/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "./src/components/Base.css"

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (window.gtag) {
    const pagePath = location
      ? location.pathname + location.search + location.hash
      : undefined

    window.gtag(`event`, `page_view`, { page_path: pagePath })
  }
}
